<template>
  <div class="store-header-class">
    <header class="centerBox1">
      <div class="flex-box">
        <img class="pointer-class" @click="enterHome()" src="@/assets/images/index/logo.png" width="160"
             height="40" alt/>
      </div>
      <div class="flex-box">
        <el-popover popper-class="inform-popover" placement="bottom" width="262" @show="popoverShow()" trigger="hover">
          <div class="inform-title">平台通知</div>
          <div class="inform-content">
            <div @click="handleClickInform(data.title,data.msg_content)" class="inform-list" :key="data.id"
                 v-for="data in messageList">
              <div>
                <img src="@/assets/images/storeManage/inform.png" alt="">
              </div>
              <div>
                <div class="popTitle">{{data.title}}</div>
                <div style="font-size: 12px;">{{data.publish_time | formatTime}}</div>
              </div>
            </div>
          </div>
          <el-badge slot="reference" :is-dot="red_point_status===1" class="item slot-btn">
            <i class="el-icon-message-solid inform-icon"></i>
          </el-badge>
          <!--<div slot="reference" class="slot-btn">
            <i class="el-icon-message-solid inform-icon"></i>
            <span class="tip"></span>
          </div>-->
        </el-popover>

<!--        @click="createStore"-->
        <div class="header-btn1" >创建店铺 <el-tooltip class="tips" effect="dark" content="系统升级中，敬请期待！" placement="top">
          <i class="el-icon-question"></i>
        </el-tooltip></div>
        <div class="line-class"></div>
        <el-popover placement="bottom-start" width="150" trigger="hover">
          <div class="login-type">
            <li style="cursor: pointer;" class="list-li" @click="toLogin()">退出登录</li>
          </div>
          <img style="cursor: pointer;" slot="reference" src="@/assets/images/storeManage/loginOut.png"
               width="20" height="20" alt/>
        </el-popover>
        <!--<div class="header-btn2">申请开户</div>-->
      </div>
    </header>
    <!--创建店铺弹窗-->
    <div>
      <div v-if="createStoreDialog || createSuccessDialog || informDialog" class="modelMask"></div>
      <el-dialog
              class="createDialogClass resetElementClass"
              width="652px"
              :close-on-click-modal="false"
              :visible.sync="createStoreDialog"
              :append-to-body="true"
              modal-append-to-body>
        <createStore v-if="createStoreDialog" @storeInfoObj="storeInfoObj"></createStore>
      </el-dialog>
      <!--创建成功弹窗-->
      <el-dialog
              class="createStoreSuccessDialogClass resetElementClass"
              width="652px"
              :close-on-click-modal="false"
              :visible.sync="createSuccessDialog"
              :append-to-body="true"
              modal-append-to-body
              @open="stopSrcoll()"
              @close="openSrcoll()">
        <createSuccess v-if="createSuccessDialog" :storeInfo="storeInfo"></createSuccess>
      </el-dialog>
      <!--通知弹窗-->
      <el-dialog
              class=" resetElementClass informDialog"
              width="652px"
              :close-on-click-modal="false"
              :visible.sync="informDialog"
              :append-to-body="true"
              modal-append-to-body
              @open="stopSrcoll()"
              @close="openSrcoll()">
        <div class="title-border">{{informtitle}}</div>
        <div class="informHtml" v-html="informContent">
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import createStore from '../../../pages/storeManagement/dialog/createStore/index'
  import createSuccess from "../../../pages/storeManagement/dialog/createSuccess/index";
  import {storeManagement} from "@/assets/js/api";
  import {handleResponseErrors} from "@/assets/js/utils";

  export default {
    name: "storeHeader",
    components: {createStore, createSuccess},
    data() {
      return {
        createStoreDialog: false,
        createSuccessDialog: false,
        informDialog: false,
        storeInfo: null,
        messageList: [],
        red_point_status: 0,
        informContent: '',
        informtitle: '',
      };
    },
    filters: {
      formatTime(timestamp) {
        try {
          if (isNaN(timestamp)) return "";
          let date = new Date(Number.parseInt(timestamp) * 1000);
          let Y = date.getFullYear();
          let M =
              date.getMonth() + 1 < 10
                  ? "0" + (date.getMonth() + 1)
                  : date.getMonth() + 1;
          let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          let m =
              date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
          let s =
              date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

          return `${Y}-${M}-${D}  ${h}:${m}:${s}`;
        } catch (error) {
          return "";
        }
      }
    },
    created() {
      this.getMessageList()
    },
    methods: {
      createStore(){
        this.createStoreDialog = true
      },
      async getMessageList() {
        try {
          let res = await storeManagement.getMessageList();
          if (res.code != 0) {
            handleResponseErrors(res.code, res.msg);
            return;
          }
          else {
            this.messageList = res.message_list;
            this.red_point_status = res.red_point_status;
          }
        } catch (error) {
          console.log(error);
          // handleResponseErrors(9000, "服务异常，请稍后重试");
        }
      },
      async clickMessage() {
        try {
          let res = await storeManagement.clickMessage();
          if (res.code != 0) {
            handleResponseErrors(res.code, res.msg);
            return;
          }
          else {
            this.red_point_status = 0;
          }
        } catch (error) {
          console.log(error);
          // handleResponseErrors(9000, "服务异常，请稍后重试");
        }
      },
      handleClickInform(title, content) {
        this.informDialog = true
        this.informtitle = title
        this.informContent = content
      },
      popoverShow() {
        if (this.red_point_status === 1) {
          this.clickMessage()
        }
      },
      storeInfoObj(v) {
        this.storeInfo = v
      },
      enterHome() {
        this.$router.push({
          path: "/",
        });
      },
      toLogin() {
        localStorage.clear();
        this.$router.push({
          path: "/login",
        });
      },
      stopSrcoll() {
        var mo = function (e) {
          e.preventDefault();
        };
        document.body.style.overflow = 'hidden';//隐藏滚动条
        document.addEventListener("touchmove", mo, false);//禁止页面滑动
      },


      openSrcoll() {
        var mo = function (e) {
          e.preventDefault();
        };
        document.body.style.overflow = '';//出现滚动条
        document.removeEventListener("touchmove", mo, false);
      },
    }
  };
</script>
<style lang="scss">
.tips{
  margin-left: 10px;
}
  .informHtml {
    blockquote, body, dd, div, dl, dt, fieldset, form, h1, h2, h3, h4, h5, h6, input, legend, li, ol, td, textarea, th, ul {
      line-height: 40px;
    }
    p{
      line-height: 28px;
    }
    table{
      width: 454px !important;
      thead,tbody,tr,td{
        width: 100%;
      }
    }
    ol,ul{
      width: 100% !important;
      padding-left: 30px;
      li{
        width: 100%;
        list-style: inherit;
      }
    }
  }

  .informDialog {
    .el-dialog {
      height: 450px !important;
    }
  }

  .inform-popover {
    padding: 0;
    .inform-title {
      height: 72px;
      text-align: center;
      line-height: 72px;
      font-weight: bold;
    }
    .inform-content {
      max-height: 360px;
      overflow-y: auto;
      .popTitle {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 170px;
        margin-bottom: 7px;
      }
      .inform-list {
        cursor: pointer;
        height: 72px;
        padding: 12px 30px;
        border-top: 1px solid #f1f1f1;
        display: flex;
        img {
          margin-right: 20px;
        }
      }
    }

    .inform-list:hover {
      background: #f1f1f1;
    }
  }
</style>
<style scoped lang="scss">
  .title-border {
    font-size: 18px;
    padding-bottom: 19px;
    margin-bottom: 19px;
    border-bottom: 1px solid #E2D6CB;
  }

  .slot-btn {
    position: relative;
    margin-right: 40px;
    cursor: pointer;
    .inform-icon {
      font-size: 22px;
    }
    .tip {
      display: inline-block;
      background: #f00;
      border-radius: 50% !important;
      width: 5px !important;
      height: 5px !important;
      position: absolute;
      right: 2px;
      top: -2px;
    }
  }

  .store-header-class {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 222;
    background: #fff;
    box-shadow: 0px 2px 3px 0px rgba(226, 214, 203, 0.2);
    .centerBox1 {
      margin: auto;
      width: 1200px;
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .flex-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .login-type {
      cursor: pointer;
      .list-li {
        cursor: pointer;
      }
      li {
        cursor: pointer;
      }
      li:hover {
        background: #5daf34;
      }
    }
    .pointer-class {
      cursor: pointer;
    }
    .line-class {
      width: 1px;
      height: 20px;
      background: #D8D8D8;
      border-radius: 1px;
      margin: 0 30px;
    }
    .title {
      cursor: pointer;
      color: #312f2e;
      font-size: 20px;
    }
    .header-btn1 {
      cursor: pointer;
      width: 124px;
      height: 38px;
      background: #fff;
      border-radius: 8px;
      font-size: 16px;
      color: #c0c4cc;
      border: 1px solid #c0c4cc;
      text-align: center;
      line-height: 38px;
    }
    .header-btn2 {
      width: 160px;
      height: 52px;
      line-height: 52px;
      text-align: center;
      font-size: 20px;
      color: #54AB8E;
      border: 1px solid #54ab8e;
      border-radius: 8px;
      cursor: pointer;
    }

  }

  .tab-switch {
    display: flex;
    margin-top: 134px;
    .tab-item {
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      color: #353535;
      line-height: 24px;
      margin-right: 48px;
    }
    .hight-light {
      color: #54AB8E !important;
    }
    .hight-light-line {
      width: 28px;
      height: 2px;
      background: #54AB8E;
      border-radius: 1px;
      margin: 10px auto;
    }
  }
</style>