<template>
    <div>
        <storeHeader></storeHeader>
        <router-view></router-view>
        <myFooter></myFooter>
    </div>
</template>

<script>
    import storeHeader from "@/components/pages/index/storeHeader";
    import myFooter from "@/components/pages/index/footer";

    export default {
        name: "storeManagement",
        components: {storeHeader, myFooter},
        data() {
            return {};
        },
        watch: {}
    };
</script>

<style lang="scss" scoped>
    .padding-top {
        height: 110px;
    }

    @import "@/assets/css/common.scss";

</style>
136(type) 17 =153 15 135(xian) 25 =175 350
