<template>
    <div class="create-store-class">
        <div v-if="copyFlag" class="title-border font-size-18">复制FunPinPin店铺</div>
        <div v-else class="title-border font-size-18">创建FunPinPin店铺</div>
        <div style="margin-bottom:28px;">
            <img src="@/assets/images/storeManage/createSuccess.png" alt="">
            <span v-if="copyFlag" class="success-text">复制成功</span>
            <span v-else class="success-text">创建成功</span>
        </div>
        <!--店铺基本信息-->
        <div class="title-border">
            <div>
                <span>店铺名称：</span>
                <span>{{storeInfo.storeName}}</span>
            </div>
            <div style="margin-top: 10px;line-height: 33px;">店铺后台登录信息已发送至您的邮箱-
                <span v-if="storeInfo.email!==storeInfo.login_email">{{storeInfo.email}}</span>
                <span v-if="storeInfo.email!==storeInfo.login_email">、</span>
                <span>{{storeInfo.login_email}}</span>
                ，请尽快查看
            </div>
        </div>
        <!--下一步可以做什么-->
        <div class="next-step">
            <p class="first-p">下一步可以做什么？</p>
            <p>-&nbsp;&nbsp;访问并登录店铺管理后台</p>
            <p>-&nbsp;&nbsp;按提示完成店铺设置和装修</p>
            <p>-&nbsp;&nbsp;进行店铺的日常运营和推广</p>
        </div>
        <!--完成按钮-->
        <div class="btn">
            <span class="sure" @click="handleSure()">完成</span>
        </div>
    </div>
</template>

<script>

    export default {
        inject: ['reload'],
        name: "createSuccess",
        props: [
            "storeInfo",
            "copyFlag"
        ],
        data() {
            return {};
        },
        mounted() {
        },
        methods: {
            handleSure() {
                this.$parent.$parent.createSuccessDialog = false
                this.reload()
            }

        },
    }
</script>

<style scoped lang="scss">
    .create-store-class {
        font-size: 16px;
        .font-size-18 {
            font-size: 18px;
        }
        .title-border {
            font-weight: 500;
            padding-bottom: 19px;
            margin-bottom: 31px;
            border-bottom: 1px solid #DEDEDE;
        }
        .success-text {
            font-size: 28px;
            position: relative;
            top: -14px;
            left: 18px;
        }
        .btn {
            margin-top: 10px;
            text-align: right;
            span {
                display: inline-block;
                width: 96px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 8px;
                cursor: pointer;
                color: #fff;

            }
            .cancel {
                background: #B7BEBC;
                margin-right: 30px;
            }
            .sure {
                background: #54AB8E;
            }
        }
        .next-step {
            font-size: 14px;
            p {
                margin-bottom: 10px;
                color: #9B9B9B;
            }
            .first-p {
                font-weight: 500;
                margin-bottom: 20px;
                color: #000000
            }
        }
    }
</style>